import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import axios from 'axios';
import '../components/HomePage.css';
import storeImage from '../assets/storefront.png';

const HomePage = () => {
  const { isLoggedIn } = useAuth();
  const [backgroundImage, setBackgroundImage] = useState('');
  const [testimonials, setTestimonials] = useState([]);
  const [newTestimonial, setNewTestimonial] = useState({ name: '', content: '' });
  const [showForm, setShowForm] = useState(false);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchBackgroundImage();
    fetchTestimonials();
  }, []);

  const fetchBackgroundImage = () => {
    axios
      .get('https://amywagnerdesigns.com/api/background-image')
      .then((response) => {
        if (response.data?.image_path) {
          const basePath = 'https://amywagnerdesigns.com/uploads';
          const imageUrl = `${basePath}/${response.data.image_path}`;
          setBackgroundImage(imageUrl);
        }
      })
      .catch((error) => console.error('Error fetching background image:', error));
  };

  const fetchTestimonials = async () => {
    try {
      const response = await axios.get('https://amywagnerdesigns.com/api/testimonials');
      setTestimonials(response.data.testimonials);
    } catch (error) {
      console.error('Error fetching testimonials:', error);
    }
  };

  const handleImageUpload = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('image', file);
      axios
        .post('https://amywagnerdesigns.com/uploadReplaceBackgroundImage', formData)
        .then(() => {
          fetchBackgroundImage();
          alert('Background image updated successfully!');
        })
        .catch((error) => {
          console.error('Error uploading image:', error);
          alert('Failed to update the background image.');
        });
    }
  };

  const handleSubmitTestimonial = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://amywagnerdesigns.com/api/testimonials', newTestimonial);
      setNewTestimonial({ name: '', content: '' });
      alert('Testimonial submitted for review.');
      setShowForm(false);
    } catch (error) {
      console.error('Error submitting testimonial:', error);
    }
  };

  const handleDeleteTestimonial = async (id) => {
    try {
      await axios.delete(`https://amywagnerdesigns.com/api/testimonials/${id}`);
      setTestimonials(testimonials.filter((testimonial) => testimonial.id !== id));
    } catch (error) {
      console.error('Error deleting testimonial:', error);
    }
  };

  const openFullScreenImage = () => {
    navigate('/fullscreen-image', { state: { backgroundImage } });
  };

  return (
    <div>
      {/* Background Image Section */}
      <div className="homepage-container" onClick={openFullScreenImage}>
        <img src={backgroundImage} alt="Background" className="homepage-image" />
        {isLoggedIn && (
          <div>
            <button onClick={handleImageUpload} style={{ color: '#000', padding: '8px', margin: '10px' }}>
              Edit Background Image
            </button>
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              onChange={handleFileInputChange}
              style={{ display: 'none' }}
            />
          </div>
        )}
      </div>

      {/* Schedule Section */}
      <div className="schedule-section">
        <p>Schedule Your Initial Consult Today!</p>
        <Link to="/contact" style={{ textDecoration: 'none', width: '100%' }}>
          <button className="schedule-button">Schedule Today!</button>
        </Link>

        {/* Store Image for Mobile */}
        <div className="store-image-container">
          <p className="store-callout">
            Shop our store for the decor that reflects your style!
          </p>
          <p className="store-subtext">
            Discover hand-selected pieces that bring elegance and charm to every space.
            Tap Below OR Swipe Right to Start Shopping Now! 🛍️
          </p>
          <a href="https://reflectionsofyoubyamy.com/" target="_blank" rel="noopener noreferrer">
            <img src={storeImage} alt="Visit Our Store" className="store-image" />
          </a>
        </div>
      </div>

      {/* Testimonials Section */}
      <div id="testimonials" className="testimonials-section">
        <h2>Testimonials</h2>
        <div className="testimonials-list">
          {testimonials.map((testimonial) => (
            <div key={testimonial.id} className="testimonial">
              <p>"{testimonial.content}"</p>
              <p><strong>- {testimonial.name}</strong></p>
              {isLoggedIn && (
                <button
                  onClick={() => handleDeleteTestimonial(testimonial.id)}
                  style={{
                    color: 'red',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    marginTop: '10px',
                  }}
                >
                  Delete
                </button>
              )}
            </div>
          ))}
        </div>

        <div className="testimonial-form">
          <button
            onClick={() => setShowForm(!showForm)}
            className="schedule-button small-button"
          >
            Submit Testimonial
          </button>
          {showForm && (
            <form onSubmit={handleSubmitTestimonial}>
              <input
                type="text"
                placeholder="Your Name"
                value={newTestimonial.name}
                onChange={(e) => setNewTestimonial({ ...newTestimonial, name: e.target.value })}
                required
              />
              <textarea
                placeholder="Your Testimonial"
                value={newTestimonial.content}
                onChange={(e) => setNewTestimonial({ ...newTestimonial, content: e.target.value })}
                required
              ></textarea>
              <button type="submit" className="schedule-button small-button">
                Submit
              </button>
            </form>
          )}
        </div>
        {isLoggedIn && (
          <div className="admin-dashboard-link">
            <Link to="/admin/dashboard" className="admin-dashboard-button">
              Go to Admin Dashboard
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomePage;







